import {AppProps} from "../../App";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import Header from "../../components/Header";
import {TitleBox} from "../../components/Component";
import PrivacyContent from "../../components/Privacy/PrivacyContent";
import LastEdit from "../../components/LastEdit";
import Footer from "../../components/Footer";
import ButtonToTop from "../../components/ButtonToTop";
import Sidebar from "../../components/Sidebar";
import {Wrapper} from "../Privacy";
import icHyangdan from "../../assets/icon/ic_hyangdan.png";
import imgHyangdan from "../../assets/image/img_hyangdan.jpeg";

export default function HyangdanChild({darkMode, setDarkMode}: AppProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    window.document.title = "향단이 아동안전표준정책 | 아이엔랩 ienlab";

    return (
        <Wrapper>
            <Header isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} darkMode={darkMode} setDarkMode={setDarkMode}/>
            <div id="wrap">
                <TitleBox className="title-wrapper" style={{backgroundImage: `url(${imgHyangdan})`}}>
                    <div>
                        <h3 className={"description"}><span>향단이 사용자를 위한</span></h3>
                        <h1 className={"title"}><span>아동안전표준정책</span></h1>
                    </div>
                </TitleBox>
                <div className="content-wrapper">
                    <div className="title">적용되는 서비스 범위</div>
                    <div className="list">
                        <div>
                            <img src={icHyangdan} />
                            <div>향단이</div>
                        </div>
                    </div>
                    <div className={"child"}>
                      <h1>아동 안전 표준 정책 문서 ("향단이" 앱)</h1>

                      <h2>1. 목적</h2>
                      <p>본 정책은 "향단이" 앱 (이하 "앱")에서 아동의 안전을 최우선으로 보호하고, 아동 관련 법규 및 Google Play 정책을 준수하기 위한 것입니다.</p>

                      <h2>2. 적용 범위</h2>
                      <p>본 정책은 앱의 모든 사용자 및 콘텐츠에 적용됩니다.</p>

                      <h2>3. 용어 정의</h2>
                      <ul>
                        <li><strong>아동</strong>: 만 13세 미만의 개인</li>
                        <li><strong>아동 관련 데이터</strong>: 아동의 개인 정보, 앱 사용 기록, 위치 정보 등 아동과 관련된 모든 데이터</li>
                        <li><strong>아동 대상 콘텐츠</strong>: 아동에게 유해하거나 부적절한 콘텐츠 (폭력, 선정성, 혐오 등)</li>
                      </ul>

                      <h2>4. 아동 관련 데이터 수집 및 사용</h2>
                      <ul>
                        <li>앱은 아동 관련 데이터를 수집하지 않습니다.</li>
                        <li>앱이 아동의 개인 정보를 수집할 필요가 있는 경우, 사전에 법정대리인의 동의를 얻고, 관련 법규를 준수합니다.</li>
                        <li>수집된 아동 관련 데이터는 안전하게 보관하고, 더 이상 필요하지 않거나 법정대리인의 요청 시 즉시 삭제합니다.</li>
                      </ul>

                      <h2>5. 아동 대상 콘텐츠 및 광고</h2>
                      <ul>
                        <li>앱은 아동에게 유해하거나 부적절한 콘텐츠를 제공하지 않습니다.</li>
                        <li>앱은 아동을 대상으로 하는 광고를 게재하지 않습니다.</li>
                        <li>앱 내 유료 콘텐츠 및 인앱 결제는 법정대리인의 동의 없이 이루어지지 않습니다.</li>
                      </ul>

                      <h2>6. 아동 안전 및 개인 정보 보호</h2>
                      <ul>
                        <li>앱은 아동 대상 온라인 괴롭힘, 유해 콘텐츠 노출 등을 방지하기 위한 노력을 기울입니다.</li>
                        <li>앱은 아동의 개인 정보 보호를 위한 보안 조치를 강화합니다.</li>
                        <li>앱은 법정대리인이 아동의 앱 사용을 관리하고 통제할 수 있도록 지원합니다.</li>
                      </ul>

                      <h2>7. 법정대리인 참여 및 통제</h2>
                      <ul>
                        <li>앱은 법정대리인이 아동 관련 데이터에 접근하고 수정할 수 있는 권한을 제공합니다.</li>
                        <li>앱은 법정대리인의 문의 및 신고를 위한 연락처 정보를 제공하고, 신속하게 처리합니다.</li>
                      </ul>

                      <h2>8. 정책 위반 시 조치</h2>
                      <ul>
                        <li>본 정책을 위반하는 사용자에게는 계정 정지, 서비스 이용 제한 등의 조치를 취할 수 있습니다.</li>
                        <li>아동 관련 법규를 위반하는 행위에 대해서는 관련 기관에 신고하고, 법적 책임을 물을 수 있습니다.</li>
                      </ul>

                      <h2>9. 정책 변경</h2>
                      <p>본 정책은 필요에 따라 변경될 수 있으며, 변경 시 앱 내 공지 또는 법정대리인에게 통지합니다.</p>

                      <h2>10. 문의</h2>
                      <p>아동 안전 관련 문의는 [연락처 정보]로 연락 주시기 바랍니다.</p>

                      <h2>11. 추가사항 (데이팅 앱 "향단이"의 경우)</h2>
                      <ul>
                        <li>앱은 엄격한 연령 제한 정책을 시행하며, 만 19세 미만의 사용자는 앱을 이용할 수 없습니다.</li>
                        <li>앱은 신뢰할 수 있는 연령 확인 및 사용자 인증 시스템을 구축하여 아동의 접근을 차단합니다.</li>
                        <li>앱은 아동 관련 사진, 영상, 메시지 등 모든 형태의 아동 관련 콘텐츠를 엄격히 금지합니다.</li>
                        <li>앱은 아동에게 유해한 행위를 하는 사용자를 감지하고 차단하는 시스템을 구축합니다.</li>
                        <li>2025년 3월 소셜 및 데이트 앱을 대상으로 Play Console 게시 전 일정한 표준을 충족하고 규정 준수 자체 인증을 요구하는 아동 안전 표준 정책이 도입되었습니다. 이 점을 명심하여 정책을 준수해야 합니다.</li>
                      </ul>

                      <h2>12. 정책 시행일</h2>
                      <p>본 정책은 2025년 3월 14일부터 시행됩니다.</p>

                      <h2>참고 자료</h2>
                      <ul>
                        <li>Google Play 정책 센터: <a href="https://developer.android.com/distribute/play-policies">https://developer.android.com/distribute/play-policies</a></li>
                        <li>Play Console 고객센터: <a href="https://support.google.com/googleplay/android-developer/answer/14747720?hl=ko">https://support.google.com/googleplay/android-developer/answer/14747720?hl=ko</a></li>
                      </ul>
                    </div>
                </div>
                <LastEdit link={location.pathname}/>
            </div>
            <Footer/>
            <ButtonToTop/>
            <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
        </Wrapper>
    );
}
